<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">

    <h2 class="text-white mb-10">{{ user.imie }} {{ user.nazwisko }}</h2>

    <div class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Nowy użytkownik</h3>
        </div>
      </div>
      <!--end::Card header-->
      <!--begin::Content-->

      <div class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-row-fluid">
              <div class="mb-6">
                <div class="row">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Imię i nazwisko:</label>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6 fv-row">
                        <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Imię" v-model="user.imie" />
                      </div>
                      <div class="col-lg-6 fv-row">
                        <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Nazwisko" v-model="user.nazwisko" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-6">
                <div class="row">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">E-mail:</label>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-12 fv-row">
                        <input type="text" :class="{ 'is-invalid': errors.email }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="E-mail" v-model="user.email" />
                        <div class="fv-plugins-message-container invalid-feedback">{{ errors.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button @click="$router.back()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
          <button @click="save" class="btn btn-primary" :disabled="requestSend">Utwórz użytkownika</button>
        </div>

      </div>
    </div>

    <div class="alert alert-light d-flex align-items-center p-5 mb-10">
      <div class="d-flex flex-column">
        <span>Po utworzeniu użytkownika, będzie on mógł skorzystać z funkcji przypomnienia hasła przy logowaniu, w celu ustawienia nowego hasła.</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'UsersDetails',
  inject: ['$profile', '$organisation'],
  props: ['id'],
  data() {
    return {
      requestSend: false,
      user: {
        'imie': '',
        'nazwisko': '',
        'haslo': '',
        'haslo_confirmation': '',
        'email': ''
      },
      errors: {
        'imie': '',
        'nazwisko': '',
        'haslo': '',
        'haslo_confirmation': '',
        'email': ''
      }
    }
  },
  mounted () {
  },
  methods: {
    save: function() {
      var self = this;
      self.requestSend = true;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      var params = {
        'imie': self.user.imie,
        'nazwisko': self.user.nazwisko,
        'email': self.user.email
      };
      this.$root.$refs.api.post('organisations/' + id + '/users', params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.user = response.data;
          self.errors = {};
          self.$router.push(self.user.uuid);
        }
      })
      .catch(error => {
        self.requestSend = false;
        self.errors = error.response.data;
      });
    }
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
